import React from "react";
import { DefaultOverview, DefaultTopics } from "./DefaultViews";

export const CommonConfigurationDiagnosticsOverview: React.StatelessComponent<{}> = (props) => {
    return <DefaultOverview />;
};

export const CommonConfigurationDiagnosticsTopics: React.StatelessComponent<{}> = (props) => {
    return <DefaultTopics />;
};
