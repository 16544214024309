import type { ObjectResourceInputs, ObjectRuntimeInputs, PathToInput, RootInputSchema } from "@octopusdeploy/step-runtime-inputs";
import { asResourceInputs, asRuntimeInputs, convertFromJsonSchemaToInputSchema } from "@octopusdeploy/step-runtime-inputs";
import React, { useCallback } from "react";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import type { UnstructuredStepResourceInputs } from "~/components/StepPackageEditor/Inputs/UnstructuredStepResourceInputs";
import type { StepInputDependencies } from "~/components/StepPackageEditor/StepInputDependencies";
import type { UnknownStepPackage } from "~/components/StepPackageEditor/StepPackage/StepPackage";
import { TypedStepPackageEditor } from "~/components/StepPackageEditor/TypedStepPackageEditor";
import { getPackagesFromInputs } from "./Inputs/getPackagesFromInputs";

export interface StepPackageEditorProps {
    stepPackage: UnknownStepPackage;
    inputs: UnstructuredStepResourceInputs;
    setInputs(inputs: UnstructuredStepResourceInputs): void;
    inputDependencies: StepInputDependencies;
    isExpandedByDefault: boolean;
    getFieldError: (name: PathToInput) => string;
    doBusyTask: DoBusyTask;
}

export function StepPackageEditor(props: StepPackageEditorProps) {
    const { editInputsForm } = props.stepPackage.stepUI;
    const inputJsonSchema = props.stepPackage.inputSchema;

    const getRootInputSchema = useCallback(
        (inputs: ObjectResourceInputs<unknown> | ObjectRuntimeInputs<unknown>): RootInputSchema => {
            return convertFromJsonSchemaToInputSchema(inputJsonSchema, inputs);
        },
        [inputJsonSchema]
    );

    const setInputs = (inputs: ObjectRuntimeInputs<unknown>) => {
        const inputSchemaForChangedInputs = getRootInputSchema(inputs);
        props.setInputs(asResourceInputs(inputSchemaForChangedInputs.properties, inputs));
        props.inputDependencies.setPackages((existingPackages) => {
            return getPackagesFromInputs(inputs, inputSchemaForChangedInputs, existingPackages);
        });
    };

    const stepResourceInputs: ObjectResourceInputs<unknown> = props.inputs ?? {};
    const inputSchema = getRootInputSchema(stepResourceInputs);
    const runtimeInputs = asRuntimeInputs(inputSchema.properties, stepResourceInputs);

    return (
        <TypedStepPackageEditor<unknown>
            getFieldError={props.getFieldError}
            editInputsForm={editInputsForm}
            getRootInputSchema={getRootInputSchema}
            inputs={runtimeInputs}
            setInputs={setInputs}
            isExpandedByDefault={props.isExpandedByDefault}
            inputDependencies={props.inputDependencies}
            doBusyTask={props.doBusyTask}
        />
    );
}
