import type { ContainerImageReference } from "@octopusdeploy/step-inputs";
import type { RuntimeContainerImageSelection } from "@octopusdeploy/step-runtime-inputs";

export function convertToRuntimeContainerImageSelection(containerImageReference: ContainerImageReference): RuntimeContainerImageSelection {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return containerImageReference as unknown as RuntimeContainerImageSelection;
}

export function convertFromRuntimeContainerImageSelection(containerImageReference: RuntimeContainerImageSelection): ContainerImageReference {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return containerImageReference as unknown as ContainerImageReference;
}
