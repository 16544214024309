import type { CategorizedPageHelpRegistration } from "app/components/ContextualHelpLayout/PageHelpRegistry/Registry/pageHelpRegistry";
import * as React from "react";
import { CommonProjectInsightsHelp } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightViews";
import { CommonInsightsMeanTimeToRecoveryOverview, CommonInsightsMeanTimeToRecoveryTopics } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightsMeanTimeToRecoveryViews";
import pageIds from "~/pageIds";

export const ProjectInsightsMeanTimeToRecoveryRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().insights.timeToRecovery,
    renderOverview: (props) => (
        <>
            <CommonInsightsMeanTimeToRecoveryOverview /> <CommonProjectInsightsHelp />
        </>
    ),
    renderTopics: (props) => <CommonInsightsMeanTimeToRecoveryTopics />,
};
