import cn from "classnames";
import * as React from "react";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip/ToolTip";
import styles from "./style.module.less";

export enum VariableStatus {
    Existing,
    New,
    Deleted,
    Modified,
}

interface VariableStatusIconProps {
    status: VariableStatus;
}

const VariableStatusIcon = (props: VariableStatusIconProps) => {
    switch (props.status) {
        case VariableStatus.New:
            return (
                <ToolTip content={`New`}>
                    <em className={cn("fa-solid fa-star", styles.newIcon)} />
                </ToolTip>
            );
        case VariableStatus.Deleted:
            return (
                <ToolTip content={`Deleted`}>
                    <em className={cn("fa-solid fa-xmark", styles.deletedIcon)} />
                </ToolTip>
            );
        case VariableStatus.Modified:
            return (
                <ToolTip content={`Changed`}>
                    <em className={cn("fa-solid fa-pencil", styles.editIcon)} />
                </ToolTip>
            );
        default:
            return null;
    }
};

export default VariableStatusIcon;
