import { useMemo } from "react";
import type { PageFields } from "~/analytics/AnalyticSession";
import { usePage } from "~/components/Page/Page";

export function usePageFields(): PageFields | null {
    const page = usePage();

    return useMemo(() => {
        if (!page) {
            return null;
        }

        return {
            "Page Area": page.Area,
            "Page Name": page.Name,
        };
    }, [page]);
}
