export const variablesLinks = (root: string) => {
    return {
        root,
        projectTemplates: `${root}/projectvariabletemplates`,
        commonTemplates: `${root}/commonvariabletemplates`,
        library: `${root}/library`,
        preview: `${root}/preview`,
        all: `${root}/all`,
    };
};

export default variablesLinks;
