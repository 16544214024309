import type { AccountReference, BoundValue, EmptyInitialValue } from "@octopusdeploy/step-inputs";
import { isBoundValue } from "@octopusdeploy/step-runtime-inputs";
import type { RuntimeAccountSelection } from "@octopusdeploy/step-runtime-inputs";
import { emptyInitialValue } from "../../emptyInitialValue";

export function convertToRuntimeAccountSelection(accountSelection: AccountReference | BoundValue | EmptyInitialValue): RuntimeAccountSelection {
    if (accountSelection === null || accountSelection === undefined || accountSelection === emptyInitialValue) {
        return "";
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (isBoundValue(accountSelection) ? accountSelection.expression : (accountSelection as unknown)) as RuntimeAccountSelection;
}

export function convertFromRuntimeAccountSelection(accountSelection: RuntimeAccountSelection): AccountReference {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return accountSelection as unknown as AccountReference;
}
