import React from "react";
import type { PageFields } from "~/analytics/AnalyticSession";
import { useAnalyticSession } from "~/analytics/AnalyticSession";
import { usePageFields } from "~/analytics/usePageFields";

const GlobalSearchActivatedName = "Global search activated";
export type ReasonForActivation = "Focused" | "HotKey";

export interface GlobalSearchActivateOptions {
    reasonForActivation: ReasonForActivation;
}

export function useNotifyGlobalSearchActivated() {
    const session = useAnalyticSession();
    const pageFields = usePageFields();

    return React.useCallback(
        (options: GlobalSearchActivateOptions) => {
            if (!pageFields) {
                return;
            }

            session.track(GlobalSearchActivatedName, mapOptionsToFields(options, pageFields));
        },
        [pageFields, session]
    );
}

interface GlobalSearchActivatedFields extends PageFields {
    "Reason for activation": ReasonForActivation;
}

function mapOptionsToFields(options: GlobalSearchActivateOptions, pageFields: PageFields): GlobalSearchActivatedFields {
    return {
        "Reason for activation": options.reasonForActivation,
        ...pageFields,
    };
}
