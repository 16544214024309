import cn from "classnames";
import * as React from "react";
import { DefaultOverview, DefaultTopics, DefaultGettingStarted } from "./Registrations/CommonComponents/DefaultViews";
import pageHelpRegistry from "./Registry/pageHelpRegistry";

interface PageHelpViewProps {
    className?: string;
    pageId: string | undefined;
}

export const PageHelpOverviewView: React.FC<PageHelpViewProps> = ({ pageId, className }) => {
    const registrations = usePageHelpRegistrationsForPage(pageId);

    if (!registrations || registrations.length === 0) {
        return <DefaultOverview />;
    }

    return (
        <div className={cn(className)}>
            {registrations.map((x) => {
                const element = x.renderOverview({
                    registration: x,
                });
                return element ? React.cloneElement(element, { key: x.page.Id }) : <DefaultOverview key={x.page.Id} />;
            })}
        </div>
    );
};

export const PageHelpTopicsView: React.FC<PageHelpViewProps> = ({ pageId, className }) => {
    const registrations = usePageHelpRegistrationsForPage(pageId);

    if (!registrations || registrations.length === 0) {
        return (
            <>
                <DefaultTopics />
                <DefaultGettingStarted />
            </>
        );
    }
    return (
        <div className={cn(className)}>
            {registrations.map((x, index) => {
                const element = x.renderTopics({
                    registration: x,
                });
                const elementToReturn = element ? React.cloneElement(element, { key: x.page.Id }) : <DefaultTopics />;
                return (
                    <div key={index}>
                        {elementToReturn}
                        <DefaultGettingStarted />
                    </div>
                );
            })}
        </div>
    );
};

function usePageHelpRegistrationsForPage(pageId: string | undefined) {
    return React.useMemo(() => pageHelpRegistry.getAllRegistrations().filter((x) => pageId === x.page.Id), [pageId]);
}
