import React from "react";

export type IScriptActionContext = {
    loadVariables: () => Promise<string[]>;
    key?: string;
};

export const ScriptActionContext = React.createContext<IScriptActionContext | undefined>(undefined);

export const useScriptActionContext = () => {
    return React.useContext(ScriptActionContext);
};
