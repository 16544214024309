import React from "react";
import type { IconSize } from "./IconSize";
import { getIconSize } from "./getIconSize";
import styles from "./styles.module.less";

export interface CloudConnectionHealthyIconProps {
    iconSize?: IconSize;
    title?: string;
}

export function CloudConnectionConfiguredIcon({ title = "Configured", iconSize }: CloudConnectionHealthyIconProps) {
    return (
        <svg {...getIconSize(iconSize)} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className={styles.statusIcon}>
            <title>{title}</title>
            <g fill="none" fillRule="evenodd">
                <circle fill="#53B953" cx="8" cy="8" r="8" />
                <path stroke="#FFF" strokeWidth=".5" fill="#FFF" d="M6.88804 11.083024L12.12 5.851064 11.296838 5 6.88804 9.42275 4.823163 7.343916 4 8.16708z" />
            </g>
        </svg>
    );
}
