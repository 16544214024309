import * as React from "react";
import { CommonInsightsReportHelp } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightViews";
import { CommonInsightsDeploymentFailureRateOverview, CommonInsightsDeploymentFailureRateTopics } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightsDeploymentFailureRateViews";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration } from "../../../Registry/pageHelpRegistry";

export const InsightsReportDeploymentFailureRateRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.insights.report.failureRate,
    renderOverview: (props) => (
        <>
            <CommonInsightsDeploymentFailureRateOverview /> <CommonInsightsReportHelp />
        </>
    ),
    renderTopics: (props) => <CommonInsightsDeploymentFailureRateTopics />,
};
