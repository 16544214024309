import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";

export const CommonLibraryCertificateOverview: React.StatelessComponent<{}> = (props) => {
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="CertificatesDocumentation">X.509 certificates</ExternalLink> are a key component of many deployment processes. Octopus Deploy provides the ability to securely store and manage your certificates, and easily use
                    them in your projects.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>
    );
};

export const CommonLibraryCertificateTopics: React.StatelessComponent<{}> = (props) => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="CertificatesDocumentation">Certificates</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
