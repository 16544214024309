import React, { useEffect } from "react";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import { InsightsTooltip } from "~/areas/insights/components/ChartTooltips/InsightsTooltip/InsightsTooltip";
import LineChart from "~/areas/insights/components/Charts/LineChart";
import { InsightsChartCard } from "~/areas/insights/components/InsightsChartCard";
import { getChartAxisDateFormatter } from "~/areas/insights/dataTransformation/dateHelpers";
import { getChartReadyInsightsData, getYAxisUnitOfTimeKeys } from "~/areas/insights/dataTransformation/getChartReadyInsightsData";
import { getEvenlySpacedTickArray, getYAxisDataMax } from "~/areas/insights/dataTransformation/getYAxisDataMax";
import { formatDurationInWords } from "~/areas/insights/dataTransformation/stringHelpers";
import { insightsCadenceLookup, insightsCadenceXAxisTickInterval } from "~/areas/insights/insightsCadence";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { DownloadProjectInsightsCsvButton } from "../DownloadProjectInsightsCsvButton";
import type { ProjectInsightsPageProps } from "../ProjectInsightsDataLoader/ProjectInsightsDataLoader";
import { ProjectInsightsDataLoader } from "../ProjectInsightsDataLoader/ProjectInsightsDataLoader";
import styles from "./ProjectInsightsTimeToRecovery.module.less";
import { ProjectTimeToRecoveryTrendTable } from "./ProjectTimeToRecoveryTrendTable";

type ProjectInsightsTimeToRecoveryInnerProps = ProjectInsightsPageProps;

function ProjectInsightsTimeToRecoveryInner({ project, bffResponse, busy, filter }: ProjectInsightsTimeToRecoveryInnerProps) {
    const { cadence } = filter;

    const chartData = getChartReadyInsightsData(bffResponse.Series);

    const { MeanTimeToRecoveryKey } = getYAxisUnitOfTimeKeys(chartData);

    const maxYAxisValue = getYAxisDataMax(chartData, `MeanTimeToRecovery.mean.${MeanTimeToRecoveryKey}`);

    const dispatchAction = useAnalyticSimpleActionDispatch();

    useEffect(() => {
        dispatchAction("View Project Insights Mean Time To Recovery");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const currentGranularity = insightsCadenceLookup[cadence].granularity;

    const dateFormatter = getChartAxisDateFormatter(currentGranularity);

    return (
        <main>
            <section className={styles.content}>
                <InsightsChartCard height={500}>
                    <LineChart
                        loading={Boolean(busy)}
                        data={chartData}
                        dataKey={`MeanTimeToRecovery.mean.${MeanTimeToRecoveryKey}`}
                        yAxisLabel={`Avg. no. of ${MeanTimeToRecoveryKey.toLowerCase()}`}
                        xAxisDataKey="StartOfInterval"
                        xAxisType="category"
                        yAxisType="number"
                        yAxisDomain={[0, maxYAxisValue]}
                        yAxisTickArray={getEvenlySpacedTickArray(maxYAxisValue)}
                        xTickFormatter={dateFormatter}
                        tooltip={<InsightsTooltip dataKey="MeanTimeToRecovery.mean.duration" valueFormatter={formatDurationInWords} cadence={cadence} />}
                        xAxisTickInterval={insightsCadenceXAxisTickInterval[cadence]}
                    />
                </InsightsChartCard>
                <div className={styles.csvDownloadWrap}>
                    <DownloadProjectInsightsCsvButton project={project} filter={filter} />
                </div>
            </section>
            <section>
                <ProjectTimeToRecoveryTrendTable
                    title="Longest time to recovery"
                    project={project}
                    note={
                        <span>
                            The successful deployments with the longest <ExternalLink href="InsightsMeanTimeToRecovery">mean time to recovery</ExternalLink> following a failure for the current filter. The first 10 in descending order by mean time to
                            recovery are shown.
                        </span>
                    }
                    data={bffResponse.MeanTimeToRecovery.LongestTimeToRecovery}
                />
                <ProjectTimeToRecoveryTrendTable
                    title="Largest number of deployments until recovery"
                    project={project}
                    note={
                        <span>
                            The successful deployments with the most <ExternalLink href="InsightsMeanTimeToRecovery">deployments until recovery</ExternalLink> following a failure for the current filter. The first 10 in descending order by count are
                            shown.
                        </span>
                    }
                    data={bffResponse.MeanTimeToRecovery.LargestNumberOfDeploymentsUntilRecovery}
                />
            </section>
        </main>
    );
}

export function ProjectInsightsTimeToRecovery() {
    return (
        <ProjectInsightsDataLoader title="Mean time to recovery" trendKey="MeanTimeToRecovery">
            {(props) => <ProjectInsightsTimeToRecoveryInner {...props} />}
        </ProjectInsightsDataLoader>
    );
}
