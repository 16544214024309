import React from "react";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form";
import { OverviewContainer, TermsText, TopicsContainer, LearnMoreResources } from "./CommonViews";
const imageForRunbookTriggers = require("../../Images/Trigger-Scheduled-Runbook.svg");

export const CommonProjectRunbookTriggersOverview: React.StatelessComponent<{}> = (props) => {
    return (
        <OverviewContainer>
            <TermsText>
                <Note heading={"Runbook triggers"}>allow you to define a recurring schedule to automate execution of a runbook.</Note>
            </TermsText>
            <ImageWithPlaceholder src={imageForRunbookTriggers} alt={"Deployment process"} />
        </OverviewContainer>
    );
};

export const CommonProjectRunbookTriggersTopics: React.StatelessComponent<{}> = (props) => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="OnboardingRunbooksLearnMore">Runbooks</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
