import React from "react";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";
const image = require("../../Images/Release.svg");

export const CommonProjectReleaseOverview: React.StatelessComponent<{}> = (props) => {
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="OnboardingReleasesLearnMore">Releases</ExternalLink> are a snapshot of your deployment process, configuration variables, and software packages. A release is deployed to the environments defined in the project's
                    lifecycle.
                </p>
                <ImageWithPlaceholder src={image} alt={"Environments"} />
                <p>
                    If your project is version-controlled, view <ExternalLink href="ConfigAsCodeDeploying">Deploying releases on version-controlled projects</ExternalLink>.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>
    );
};

export const CommonProjectReleaseTopics: React.StatelessComponent<{}> = (props) => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="OnboardingReleasesLearnMore">Deploying Releases</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="ConfigAsCodeDeploying">Deploying releases on version-controlled projects</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
