import React from "react";
import { useAnalyticSession } from "~/analytics/AnalyticSession";

const NotificationsIconClicked = "Notifications Icon Clicked";

export function useDispatchNotificationsIconClicked() {
    const session = useAnalyticSession();

    return React.useCallback(
        (options: NotificationIconOpenedOptions) => {
            session.track(NotificationsIconClicked, mapOptionsToFields(options));
        },
        [session]
    );
}

interface NotificationIconOpenedOptions {
    hasActiveAlert: boolean;
}

interface NotificationIconOpenedFields {
    "Has Active Alert": boolean;
}

function mapOptionsToFields(options: NotificationIconOpenedOptions): NotificationIconOpenedFields {
    return { "Has Active Alert": options.hasActiveAlert };
}
