import type { CategorizedPageHelpRegistration } from "app/components/ContextualHelpLayout/PageHelpRegistry/Registry/pageHelpRegistry";
import * as React from "react";
import { LearnMoreResources, OverviewContainer, OverviewHighlightText, TopicsContainer } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonViews";
import ExternalLink from "~/components/Navigation/ExternalLink";
import pageIds from "~/pageIds";

export const ProjectInsightsOverviewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().insights.overview,
    renderOverview: (props) => (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    The Insights <b> Overview</b> page is where you can view charts for all four Insights metrics at once. To see more detail, select one of the four metrics links on the left.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>
    ),
    renderTopics: (props) => {
        return (
            <TopicsContainer>
                <LearnMoreResources>
                    <div>
                        <ExternalLink href="ProjectInsights">Project Insights</ExternalLink>
                    </div>
                </LearnMoreResources>
            </TopicsContainer>
        );
    },
};
