import React from "react";
import { OverviewContainer, TopicsContainer, LearnMoreResources, TermsText } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonViews";
import { Note } from "~/components/form/index";

export const CommonInsightsOverview: React.FC = (props) => <OverviewContainer></OverviewContainer>;

export const CommonInsightsTopics: React.FC = (props) => (
    <TopicsContainer>
        <LearnMoreResources />
    </TopicsContainer>
);

export const CommonInsightsReportHelp: React.FC = (props) => (
    <TermsText>
        <Note heading={"The 'Split by' selector"}>allows you to compare differences between projects, project groups, environments, or environment groups.</Note>
        <Note heading={"The 'Date range' selector"}>allows you to choose the time-frame and granularity of the data in the chart.</Note>
    </TermsText>
);

export const CommonProjectInsightsHelp: React.FC = (props) => (
    <TermsText>
        <Note heading={"The 'Date range' selector"}>allows you to choose the time-frame and granularity of the data in the chart.</Note>
    </TermsText>
);
