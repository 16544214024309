import { get } from "lodash";
import type { ObjectPropertyPath } from "~/utils/ObjectPropertyPath/ObjectPropertyPath";
import type { MultiSeriesData } from "../components/Charts/LineChart";

// The supplied key must point to a number
export function getYAxisDataMax<T>(data: MultiSeriesData<T>[], key: ObjectPropertyPath<T>) {
    const allValues: number[] = [];

    data.forEach((s) => {
        s.data.forEach((d) => {
            const val = get(d, key);

            if (typeof val !== "number") {
                throw Error("The supplied key does not point to a number");
            }

            allValues.push(val);
        });
    });

    const maxValue = Math.max(...allValues);
    return maxValue < 4 ? 4 : Math.ceil(maxValue);
}

// Assumes we always want the y axis to start from 0
export function getEvenlySpacedTickArray(maxValue: number) {
    return [0, Math.ceil(maxValue / 4), Math.ceil(maxValue / 2), Math.ceil((3 * maxValue) / 4), maxValue];
}
