import React, { useEffect } from "react";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import { InsightsTooltip } from "~/areas/insights/components/ChartTooltips/InsightsTooltip/InsightsTooltip";
import LineChart from "~/areas/insights/components/Charts/LineChart";
import { InsightsChartCard } from "~/areas/insights/components/InsightsChartCard";
import { SparklineTrendPair } from "~/areas/insights/components/Reports/TrendTables/SparklineTrendPair/SparklineTrendPair";
import { getChartAxisDateFormatter } from "~/areas/insights/dataTransformation/dateHelpers";
import { getChartReadyInsightsData, getYAxisUnitOfTimeKeys } from "~/areas/insights/dataTransformation/getChartReadyInsightsData";
import { getEvenlySpacedTickArray, getYAxisDataMax } from "~/areas/insights/dataTransformation/getYAxisDataMax";
import { formatDurationInWords } from "~/areas/insights/dataTransformation/stringHelpers";
import { insightsCadenceLookup, insightsCadenceXAxisTickInterval } from "~/areas/insights/insightsCadence";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { DownloadProjectInsightsCsvButton } from "../DownloadProjectInsightsCsvButton";
import type { ProjectInsightsPageProps } from "../ProjectInsightsDataLoader/ProjectInsightsDataLoader";
import { ProjectInsightsDataLoader } from "../ProjectInsightsDataLoader/ProjectInsightsDataLoader";
import styles from "./ProjectInsightsLeadTime.module.less";
import { ProjectLeadTimeTrendTable } from "./ProjectLeadTimeTrendTable";

type ProjectInsightsLeadTimeInnerProps = ProjectInsightsPageProps;

function ProjectInsightsLeadTimeInner({ project, bffResponse, busy, filter }: ProjectInsightsLeadTimeInnerProps) {
    const { cadence } = filter;

    const chartData = getChartReadyInsightsData(bffResponse.Series);

    const { LeadTimeKey } = getYAxisUnitOfTimeKeys(chartData);

    const maxYAxisValue = getYAxisDataMax(chartData, `LeadTime.mean.${LeadTimeKey}`);

    const dispatchAction = useAnalyticSimpleActionDispatch();

    useEffect(() => {
        dispatchAction("View Project Insights Lead Time");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const currentGranularity = insightsCadenceLookup[cadence].granularity;

    const dateFormatter = getChartAxisDateFormatter(currentGranularity);

    return (
        <main>
            <section className={styles.content}>
                <InsightsChartCard height={500}>
                    <LineChart
                        loading={Boolean(busy)}
                        data={chartData}
                        dataKey={`LeadTime.mean.${LeadTimeKey}`}
                        yAxisLabel={`Avg. no. of ${LeadTimeKey.toLowerCase()}`}
                        xAxisDataKey="StartOfInterval"
                        xAxisType="category"
                        yAxisType="number"
                        yAxisDomain={[0, maxYAxisValue]}
                        yAxisTickArray={getEvenlySpacedTickArray(maxYAxisValue)}
                        xTickFormatter={dateFormatter}
                        tooltip={<InsightsTooltip dataKey="LeadTime.mean.duration" valueFormatter={formatDurationInWords} cadence={cadence} />}
                        xAxisTickInterval={insightsCadenceXAxisTickInterval[cadence]}
                    />
                </InsightsChartCard>
                <div className={styles.csvDownloadWrap}>
                    <DownloadProjectInsightsCsvButton project={project} filter={filter} />
                </div>
            </section>
            <section>
                {bffResponse.LeadTime.TenantTrends !== null && (
                    <SparklineTrendPair
                        sectionTitle="Tenants"
                        leftData={bffResponse.LeadTime.TenantTrends.Up}
                        rightData={bffResponse.LeadTime.TenantTrends.Down}
                        leftTitle="Trending up"
                        rightTitle="Trending down"
                        note={
                            <>
                                The tenants that have trended up or down in the last 28 days compared with the previous 28 days. The first 10 in descending order by the percentage difference between the current and previous value are shown. The value
                                shown is the average <ExternalLink href="InsightsDeploymentLeadTime">lead time</ExternalLink> for the last 28 days.
                            </>
                        }
                    />
                )}
                <ProjectLeadTimeTrendTable
                    title="Releases with the longest lead time"
                    note={
                        <span>
                            The releases with the longest <ExternalLink href="InsightsDeploymentLeadTime">lead time</ExternalLink> for the current filter. The first 10 in descending order by lead time are shown. Lower lead time indicates an increased
                            ability to respond to change and implies less inventory per deployment, which reduces the risk of that response.
                        </span>
                    }
                    data={bffResponse.LeadTime.Longest}
                    project={project}
                />
                <ProjectLeadTimeTrendTable
                    title="Deployments that have recently succeeded"
                    note={
                        <span>
                            The 10 most recent deployments are shown along with their <ExternalLink href="InsightsDeploymentLeadTime">lead time</ExternalLink> and the oldest previously undeployed release
                        </span>
                    }
                    data={bffResponse.LeadTime.LatestSuccessful}
                    project={project}
                />
            </section>
        </main>
    );
}

export function ProjectInsightsLeadTime() {
    return (
        <ProjectInsightsDataLoader title="Deployment lead time" trendKey="LeadTime">
            {(props) => <ProjectInsightsLeadTimeInner {...props} />}
        </ProjectInsightsDataLoader>
    );
}
