import React from "react";
import { LearnMoreResources, OverviewContainer, OverviewHighlightText, TopicsContainer } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonViews";
import ExternalLink from "~/components/Navigation/ExternalLink";

export const CommonInsightsDeploymentLeadTimeOverview: React.FC = (props) => (
    <OverviewContainer>
        <OverviewHighlightText>
            <p>
                <b>Deployment lead time</b> is the time period from when a release is created, to when the contents of that release are deployed to a given environment.
            </p>
        </OverviewHighlightText>
        <OverviewHighlightText>
            <p>
                <b>How it's calculated</b>
                <br />
                The lead time for a given deployment is the time from the deployment's completion date, back to the creation date of the earliest release whose changes have not been deployed to the given environment.
            </p>
        </OverviewHighlightText>
    </OverviewContainer>
);

export const CommonInsightsDeploymentLeadTimeTopics: React.FC = (props) => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="InsightsDeploymentLeadTime">Deployment lead time</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
