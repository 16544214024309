import React from "react";
import WarningIcon from "~/components/WarningIcon/index";
import styles from "./styles.module.less";

export function VcsErrorPanel(props: { content: JSX.Element }) {
    return (
        <div className={styles.vcsErrorPanel}>
            <div className={styles.titleContainer}>
                <WarningIcon color="dangerHeaderText" />
                <span className={styles.title}>There was an error accessing version control.</span>
            </div>
            <div className={styles.content}>{props.content}</div>
        </div>
    );
}
