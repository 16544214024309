import { CloudConnectionWarningIcon } from "app/areas/projects/components/CloudConnection/CloudConnectionWarningIcon";
import * as React from "react";
import { CloudConnectionConfiguredIcon } from "~/areas/projects/components/CloudConnection/CloudConnectionConfiguredIcon";
import type { IconSize } from "./IconSize";

export enum CloudConnectionStatus {
    Ok,
    Warning,
}

interface CloudConnectionStatusIconProps {
    connectionStatus: CloudConnectionStatus;
    iconSize?: IconSize;
}

export function CloudConnectionStatusIcon({ connectionStatus, iconSize }: CloudConnectionStatusIconProps) {
    switch (connectionStatus) {
        case CloudConnectionStatus.Warning:
            return <CloudConnectionWarningIcon></CloudConnectionWarningIcon>;
        case CloudConnectionStatus.Ok:
            return <CloudConnectionConfiguredIcon iconSize={iconSize}></CloudConnectionConfiguredIcon>;
    }

    return <></>;
}
