/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ReactNode } from "react";
import React, { useState } from "react";
import type { TooltipProps } from "recharts";
import { Legend, LineChart as RechartsLineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Label } from "recharts";
import type { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import type { AxisDomain, Margin } from "recharts/types/util/types";
import BusyIndicator from "~/components/BusyIndicator";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import type { ObjectPropertyPath } from "~/utils/ObjectPropertyPath/ObjectPropertyPath";
import { addColorToDataset, useThemedChartColors } from "../chartColors";
import ResponsiveChartContainer from "./ResponsiveChartContainer";
import styles from "./style.module.less";

export interface MultiSeriesData<TResource> {
    name: string;
    id: string;
    data: TResource[];
}

export interface BaseChartProps<TResource> {
    data: MultiSeriesData<TResource>[];
    dataKey: ObjectPropertyPath<TResource>;
    xAxisDataKey: ObjectPropertyPath<TResource>;
    yAxisDataKey?: ObjectPropertyPath<TResource>;
    xAxisLabel?: string;
    yAxisLabel?: string;
    xAxisType?: "number" | "category";
    yAxisType?: "number" | "category";
    margin?: Margin;
    yTickFormatter?: (value: any, index: number) => string;
    xTickFormatter?: (value: any, index: number) => string;
    xAxisDomain?: AxisDomain;
    yAxisDomain?: AxisDomain;
    loading?: boolean;
    yAxisTickArray?: (string | number)[];
    xAxisTickInterval?: number;
}

interface LineChartProps<TResource> extends BaseChartProps<TResource> {
    tooltip?: JSX.Element | ((props: TooltipProps<ValueType, NameType>) => ReactNode);
    showLegend?: boolean;
}

const truncateSeriesName = (str: string, len = 50) => {
    if (str.length >= len) return `${str.slice(0, len - 3)}...`;
    return str;
};

function LineChart<TResource>({
    data,
    dataKey,
    xAxisDataKey,
    yAxisDataKey,
    xAxisLabel,
    yAxisLabel,
    xTickFormatter,
    yTickFormatter,
    xAxisDomain,
    yAxisDomain,
    xAxisType,
    yAxisType,
    margin,
    tooltip,
    showLegend,
    loading,
    yAxisTickArray,
    xAxisTickInterval,
}: LineChartProps<TResource>) {
    const palette = useThemePaletteType();
    const isLightMode = palette === "light";

    const [hiddenSeries, setHiddenSeries] = useState<string[]>([]);

    const [hoveredSeries, setHoveredSeries] = useState<string | null>(null);

    const chartColors = useThemedChartColors();
    const { strokes } = chartColors;

    const dataWithColors = addColorToDataset(data);

    const handleLegendClick = (seriesId: string) => {
        if (hiddenSeries.some((s) => s === seriesId)) setHiddenSeries((p) => p.filter((s) => s !== seriesId));
        else setHiddenSeries((p) => [...p, seriesId]);
    };

    const handleLegendMouseEnter = (seriesId: string) => setHoveredSeries(seriesId);

    const handleLegendMouseLeave = () => setHoveredSeries(null);

    const DEFAULT_MARGIN = {
        top: 10,
        right: showLegend ? 10 : 40,
        left: 80,
        bottom: 10,
    };

    if (loading)
        return (
            <div className={styles.loading}>
                <BusyIndicator show inline size={44} thickness={4} />
            </div>
        );

    return (
        <ResponsiveChartContainer>
            {({ width, height }) => (
                <RechartsLineChart margin={margin ?? DEFAULT_MARGIN} width={width} height={height} className={styles.toggleableLegend}>
                    <CartesianGrid strokeDasharray="6" stroke={chartColors.grid} vertical={false} />
                    <XAxis
                        dataKey={xAxisDataKey}
                        domain={xAxisDomain}
                        type={xAxisType}
                        stroke={chartColors.xAxis}
                        tickFormatter={xTickFormatter}
                        allowDuplicatedCategory={false}
                        tickMargin={16}
                        tickLine={false}
                        color={chartColors.xAxis}
                        interval={xAxisTickInterval}
                    >
                        {xAxisLabel && <Label value={xAxisLabel} position="bottom" stroke={chartColors.label} fontSize={20} fontWeight={300} strokeWidth={1} style={{ textAnchor: "middle" }} />}
                    </XAxis>
                    <YAxis
                        dataKey={yAxisDataKey}
                        domain={yAxisDomain}
                        type={yAxisType}
                        stroke={chartColors.yAxis}
                        tickFormatter={yTickFormatter}
                        width={20}
                        axisLine={false}
                        tickMargin={16}
                        tickLine={false}
                        color={chartColors.yAxis}
                        ticks={yAxisTickArray}
                    >
                        {yAxisLabel && <Label angle={-90} value={yAxisLabel} position="left" stroke={chartColors.label} fontSize={20} fontWeight={300} strokeWidth={1} style={{ textAnchor: "middle" }} dx={-60} />}
                    </YAxis>
                    {showLegend && (
                        <Legend
                            onClick={(e) => handleLegendClick(e.payload.id)}
                            onMouseOver={(e) => handleLegendMouseEnter(e.payload.id)}
                            onMouseOut={handleLegendMouseLeave}
                            iconType="plainline"
                            layout="vertical"
                            verticalAlign="top"
                            align="right"
                            formatter={(val) => truncateSeriesName(val)}
                            wrapperStyle={{
                                paddingLeft: 36,
                                maxWidth: 250,
                                height: height - 36,
                                overflow: "auto",
                                fontWeight: 500,
                            }}
                        />
                    )}
                    {tooltip && <Tooltip content={tooltip} wrapperStyle={{ zIndex: 1 }} animationDuration={200} />}
                    {dataWithColors.map((d) => {
                        const hoveredIsHidden = hiddenSeries.some((h) => h === hoveredSeries);
                        const stroke = strokes[d.color];
                        const white = "#FFFFFF";

                        const activeDot = { stroke: isLightMode ? stroke : white, fill: isLightMode ? white : stroke, strokeWidth: 2, r: 6 };

                        return (
                            <Line
                                key={`data-${d.name}`}
                                name={d.name}
                                id={d.id}
                                type="monotone"
                                dataKey={dataKey}
                                data={d.data}
                                stroke={stroke}
                                hide={hiddenSeries.some((s) => s === d.id)}
                                strokeOpacity={hoveredSeries === d.id || !hoveredSeries || hoveredIsHidden ? 1 : 0.1}
                                strokeWidth={3}
                                dot={false}
                                activeDot={activeDot}
                                animationDuration={500}
                            />
                        );
                    })}
                </RechartsLineChart>
            )}
        </ResponsiveChartContainer>
    );
}

export default LineChart;
