import * as React from "react";
import { CommonInsightsTopics } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightViews";
import { OverviewContainer, OverviewHighlightText } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonViews";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration } from "../../../Registry/pageHelpRegistry";

export const InsightsReportSettingsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.insights.report.settings,
    renderOverview: (props) => (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <b>Insights Report Settings</b> allow you to edit details about the report, such as the name, description, and add a logo. It also allows you to manage the project groups and environments groups used within the report.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>
    ),
    renderTopics: (props) => <CommonInsightsTopics />,
};
