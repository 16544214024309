import * as React from "react";
import { LearnMoreResources, TopicsContainer } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonViews";
import ExternalLink from "~/components/Navigation/ExternalLink";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration } from "../../../Registry/pageHelpRegistry";
import { DefaultOverview } from "../../CommonComponents/DefaultViews";

export const InsightsReportListRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.insights.reports,
    renderOverview: () => <DefaultOverview />,
    renderTopics: () => {
        return (
            <TopicsContainer>
                <LearnMoreResources>
                    <div>
                        <ExternalLink href="InsightsReports">Insights Reports</ExternalLink>
                    </div>
                </LearnMoreResources>
            </TopicsContainer>
        );
    },
};
