import * as React from "react";
import { AnalyticLinkProvider } from "~/analytics/AnalyticLink";
import OnboardingBlankPage from "~/components/ProjectBasedActivation/OnboardingBlankPage";

interface OnboardingPageProps {
    title: string;
    intro?: React.ReactNode | string;
    learnMore: React.ReactNode;
    image?: string;
    imageDark?: string;
    imageAlt?: string;
    actionButtons?: React.ReactNode;
}

class OnboardingPage extends React.Component<OnboardingPageProps, never> {
    render() {
        return (
            <AnalyticLinkProvider location="Onboarding Page">
                <OnboardingBlankPage
                    title={this.props.title}
                    description={this.props.intro}
                    image={this.props.image}
                    imageDark={this.props.imageDark}
                    imageAlt={this.props.imageAlt}
                    learnMore={this.props.learnMore}
                    actionButtons={this.props.actionButtons}
                />
            </AnalyticLinkProvider>
        );
    }
}

export default OnboardingPage;
