import React from "react";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form";
import { LearnMoreResources, TopicsContainer, WebinarResources, OverviewContainer, OverviewHighlightText, TermsText } from "./CommonViews";
const HelpImageWorker = require("../../Images/Worker.svg");

export const CommonWorkerOverview: React.StatelessComponent<{}> = (props) => {
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="Workers">Workers</ExternalLink> let you delegate work to a machine other than the Octopus server for increased speed, scale and security.
                </p>
                <ImageWithPlaceholder src={HelpImageWorker} alt={"Workers"} />
            </OverviewHighlightText>
            <TermsText>
                <Note heading={"How workers work?"}>
                    If a deployment step is configured to run on a worker from a worker pool, Octopus will select any worker in that pool to execute the step. It executes the step using Calamari and returns the logs and any collected artifacts to the
                    Octopus server.
                </Note>
            </TermsText>
        </OverviewContainer>
    );
};

export const CommonWorkerTopics: React.StatelessComponent<{}> = (props) => {
    return (
        <TopicsContainer>
            <WebinarResources>
                <div>
                    <ExternalLink href="WebinarWorkersSpaces">Workers and Spaces webinar</ExternalLink>
                </div>
            </WebinarResources>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="OnboardingWorkersLearnMore">Workers</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
