import React from "react";
import InternalLink from "~/components/Navigation/InternalLink";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import routeLinks from "~/routeLinks";

export function InsightsNoEnvironmentsCallout() {
    return (
        <Callout type={CalloutType.Warning} title="Insights cannot be rendered without environments">
            <InternalLink to={routeLinks.infrastructure.environments.root}>Create your first environment</InternalLink> in the infrastructure area.
        </Callout>
    );
}
