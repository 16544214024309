import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";

export const CommonProjectDeploymentOverview: React.StatelessComponent<{}> = (props) => {
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                When you <ExternalLink href="OnboardingReleasesLearnMore">Deploy a release</ExternalLink>, you are executing the deployment process with all the associated details, as they existed when the release was created. You can Deploy a
                Release as many times as you want to.
            </OverviewHighlightText>
        </OverviewContainer>
    );
};

export const CommonProjectDeploymentTopics: React.StatelessComponent<{}> = (props) => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="OnboardingReleasesLearnMore">Deploying Releases</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
