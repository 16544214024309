import React from "react";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";
const image = require("../../Images/Lifecycle.svg");

export const CommonLibraryLifecycleOverview: React.StatelessComponent<{}> = (props) => {
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="OnboardingLifecycles">Lifecycles</ExternalLink> give you control over the way releases are promoted between environments.
                </p>
                <p>
                    <ExternalVideoLink href="OnboardingLifecyclesVideo" />
                </p>
                <ImageWithPlaceholder src={image} alt={"Lifecycle"} />
            </OverviewHighlightText>
        </OverviewContainer>
    );
};

export const CommonLibraryLifecycleTopics: React.StatelessComponent<{}> = (props) => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="OnboardingLifecycles">Lifecycles</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
