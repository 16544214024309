import cn from "classnames";
import React from "react";
import type { TaskWithPosition } from "~/areas/tasks/components/Task/TaskQueueOrderedList";
import styles from "~/areas/tasks/components/Task/taskqueue.module.less";
import Chip from "~/components/Chips/Chip";
import TaskDetails from "~/components/TaskDetails/index";
import { useOctopusTheme } from "~/components/Theme/index";

interface TaskQueueRowProps {
    task: TaskWithPosition;
}

function getPositionWithSuffix(n: number): string {
    const firstDigit = n % 10;

    switch (firstDigit) {
        case 1:
            return `${n}st`;
        case 2:
            return `${n}nd`;
        case 3:
            return `${n}rd`;
        default:
            return `${n}th`;
    }
}

export default ({ task }: TaskQueueRowProps) => {
    const positionText = getPositionWithSuffix(task.Position);
    const theme = useOctopusTheme();

    return (
        <div className={cn(styles.taskQueueRow)}>
            <span className={styles.taskPosition}>{positionText}</span>
            <div className={styles.taskDetails}>
                <TaskDetails task={task} stripTopBottomPadding={true} />
            </div>
            {task.Current && (
                <div className={styles.taskCurrent}>
                    <Chip backgroundColor={theme.chipHighlight} labelColor={theme.darkText} noTooltip={true}>
                        Your Task
                    </Chip>
                </div>
            )}
        </div>
    );
};
