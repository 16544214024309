import React from "react";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";
const image = require("../../Images/Channels.svg");

export const CommonProjectChannelOverview: React.StatelessComponent<{}> = (props) => {
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="Channels">Channels</ExternalLink> allow you to dynamically change the deployment logic and lifecycle of a project based on the release being deployed.
                </p>
                <ImageWithPlaceholder src={image} alt={"Channels"} />
            </OverviewHighlightText>
        </OverviewContainer>
    );
};

export const CommonProjectChannelTopics: React.StatelessComponent<{}> = (props) => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="Channels">Channels</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
