/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { connect } from "react-redux";
import type { Action, Dispatch } from "redux";
import type { ExpanderContainer } from "~/components/form/Sections/reducers/expanders";
import { defaultExpanderContainer, expanderActions, UseInitialValueOfContainer } from "~/components/form/Sections/reducers/expanders";

type ExpanderSet = { [errorKey: string]: boolean | undefined };
interface StateProps {
    expanders: ExpanderSet;
    getExpanderValueForKey(key: string): boolean | undefined;
}

interface GlobalDispatchProps {
    onExpandedChanged(key: string, isExpanded: boolean): void;
    registerAllExpanders(keys: string[]): void;
}

export type GroupedExpandableProps = StateProps & GlobalDispatchProps;

// HOC that hooks up a component that manages expansion for multiple child components
// to the redux store
export default function Expandable<Props>(containerKey: string, Comp: React.ComponentClass<Props & GroupedExpandableProps>) {
    type InternalProps = StateProps & GlobalDispatchProps;
    type ExternalProps = Omit<InternalProps & Props, keyof GroupedExpandableProps>;

    class ExpandableInternal extends React.Component<InternalProps> {
        render() {
            return <Comp {...(this.props as InternalProps & Props)} />;
        }
    }

    function mapGlobalStateToProps(state: GlobalState): StateProps {
        const container: ExpanderContainer = state.expanders?.[containerKey] ?? defaultExpanderContainer();
        const expanders: ExpanderSet = Object.keys(container.expanderValues).reduce((values: { [key: string]: boolean }, expanderKey) => {
            values[expanderKey] = getExpanderValueForKey(expanderKey);
            return values;
        }, {});

        function getExpanderValueForKey(expanderKey: string) {
            const lowercaseKey = expanderKey.toLowerCase();
            const storedValue = container.expanderValues[lowercaseKey];
            return storedValue === UseInitialValueOfContainer ? container.initialState : storedValue;
        }

        return { expanders, getExpanderValueForKey };
    }

    function mapGlobalActionDispatchersToProps(dispatch: Dispatch<Action>, props: Props): GlobalDispatchProps {
        return {
            registerAllExpanders: (keys: string[]) => {
                dispatch(expanderActions.onAllExpandersCreated({ containerKey, keys: keys.map((x) => x.toLowerCase()), expanded: false }));
            },
            onExpandedChanged: (key: string, state: boolean) => {
                dispatch(expanderActions.onExpanderStateChanged({ containerKey, key: key.toLowerCase(), expanded: state }));
            },
        };
    }

    return connect<StateProps, GlobalDispatchProps, Props, GlobalState>(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(ExpandableInternal) as any as React.ComponentType<ExternalProps>;
}
