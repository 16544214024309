import React from "react";
import { useAnalyticSession } from "~/analytics/AnalyticSession";

const NotificationCallToActionClicked = "Notification call to action clicked";

export function useDispatchNotificationCallToActionClicked() {
    const session = useAnalyticSession();

    return React.useCallback(
        (options: NotificationIconOpenedOptions) => {
            session.track(NotificationCallToActionClicked, mapOptionsToFields(options));
        },
        [session]
    );
}

interface NotificationIconOpenedOptions {
    notificationId: string;
}

interface NotificationIconOpenedFields {
    "Notification Id": string;
}

function mapOptionsToFields(options: NotificationIconOpenedOptions): NotificationIconOpenedFields {
    return {
        "Notification Id": options.notificationId,
    };
}
