import React from "react";
import styles from "./style.module.less";

type Props = {
    slug?: string | null;
};

export default function SlugSummary(props: Props) {
    if (props.slug) {
        return <span className={styles.slugSummary}>{props.slug}</span>;
    }

    return <></>;
}
