import type { SVGProps } from "react";
import type { IconSize } from "./IconSize";

export function getIconSize(size: IconSize = "small"): Pick<SVGProps<unknown>, "height" | "width"> {
    switch (size) {
        case "small":
            return { width: "16px", height: "16px" };
        case "medium":
            return { width: "24px", height: "24px" };
        case "large":
            return { width: "32px", height: "32px" };
    }
}
