import React from "react";
import { withTheme } from "~/components/Theme";
import WarningIcon from "~/components/WarningIcon/index";
import { GitBranchIcon } from "~/primitiveComponents/dataDisplay/Icon";
import styles from "./style.module.less";

export function GitProjectLinkWithErrorStatus(props: { label: string | JSX.Element; hasError: boolean }) {
    return withTheme((theme) => {
        const icon = props.hasError ? <WarningIcon color={theme.errorText} /> : undefined;
        return <IconProjectLink {...props} icon={icon} forceIndented={true}></IconProjectLink>;
    });
}

export function GitProjectLink(props: { label: string | JSX.Element }) {
    return <IconProjectLink {...props} icon={<GitBranchIcon />}></IconProjectLink>;
}

export function IndentedProjectLink(props: { label: string | JSX.Element }) {
    return <IconProjectLink {...props} icon={undefined} forceIndented={true}></IconProjectLink>;
}

export function IconProjectLink(props: { label: string | JSX.Element; icon: JSX.Element | undefined; forceIndented?: boolean }) {
    const noIconStyle = props.forceIndented ? styles.noIconIndented : styles.noIcon;

    const icon =
        props.icon === undefined ? (
            <div className={noIconStyle} />
        ) : (
            React.cloneElement(props.icon, {
                fontSize: "small",
                className: styles.icon,
            })
        );

    return (
        <div className={styles.linkContainer}>
            {icon}
            {props.label}
        </div>
    );
}
