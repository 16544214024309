import React from "react";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import DataBaseComponent from "~/components/DataBaseComponent/index";
import { withPage } from "~/components/Page/Page";
import PaperLayout from "~/components/PaperLayout";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import { ReportUpsell } from "../Reports/ReportUpsell/ReportUpsell";

export interface LicenseGuardProps {
    children: JSX.Element;
}

interface State {
    licenseChecked: boolean;
    hasInsightsLicenseFeature: boolean;
    hasFeatureRecentlyExpired: boolean;
}

const UpsellPage = withPage({ page: pageIds.insights.upsell })(ReportUpsell);

function LoadingLayout() {
    return (
        <PaperLayout fullWidth flatStyle busy>
            <AreaTitle link={routeLinks.insights.root} title="Insights" hideHelpButton />
        </PaperLayout>
    );
}

class LicenseChecker extends DataBaseComponent<LicenseGuardProps, State> {
    constructor(props: LicenseGuardProps) {
        super(props);
        this.state = {
            licenseChecked: false,
            hasInsightsLicenseFeature: false,
            hasFeatureRecentlyExpired: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const licenseCheck = await repository.InsightsReports.getInsightsLicenseCheck();
            this.setState({ licenseChecked: true, hasInsightsLicenseFeature: licenseCheck.HasInsightsLicenseFeature, hasFeatureRecentlyExpired: licenseCheck.HasFeatureRecentlyExpired });
        });
    }

    render() {
        const { hasInsightsLicenseFeature, hasFeatureRecentlyExpired } = this.state;

        if (this.state.busy || !this.state.licenseChecked) return <LoadingLayout />;

        if (hasInsightsLicenseFeature) return this.props.children;

        return <UpsellPage showLicenseExpired={hasFeatureRecentlyExpired} />;
    }
}

export default LicenseChecker;
