import * as React from "react";
import { GitCallouts } from "~/areas/projects/components/VersionControl/GitCallouts";
import type { PaperLayoutProps } from "~/components/PaperLayout";
import PaperLayout from "~/components/PaperLayout";

export const ProjectContextPaperLayout: React.FC<PaperLayoutProps> = ({ children, ...rest }) => (
    <PaperLayout {...rest} callout={<GitCallouts />}>
        {children}
    </PaperLayout>
);
