import type { ObjectInputPaths, StepConfigurationExportInputs } from "@octopusdeploy/step-inputs";
import type { RuntimeContainerImageSelection } from "@octopusdeploy/step-runtime-inputs";
import type { StepInputComponent, StepUI } from "@octopusdeploy/step-ui";
import {
    bold,
    checkbox,
    code,
    containerImageSelector,
    inlineCheckbox,
    inlineList,
    inlineSelect,
    inlineText,
    italic,
    link,
    list,
    note,
    number,
    packageSelector,
    radioButtons,
    section,
    sectionGroup,
    select,
    sensitiveText,
    text,
} from "@octopusdeploy/step-ui";
import type { SampleStepInputs, Union } from "~/components/Actions/sample/SampleStepInputs";
import { Options } from "~/components/Actions/sample/SampleStepInputs";
import { convertFromRuntimeContainerImageSelection } from "../../StepPackageEditor/Inputs/Components/ContainerImageSelector/ContainerImageConverters";

const createEmptyContainerImageReference = () => {
    const containerImageSelection: RuntimeContainerImageSelection = {
        referenceId: "random-guid",
        feedId: undefined,
        imageName: undefined,
    };
    return convertFromRuntimeContainerImageSelection(containerImageSelection);
};

export const sampleStepUI: StepUI<SampleStepInputs> = {
    createInitialInputs: () => ({
        string: "Initial value",
        union: {
            type: "first",
            valueFromFirst: "Another initial value",
        },
        secondUnion: {
            type: "second",
            valueFromSecond: {
                type: "empty",
            },
        },
        list: [
            {
                string: "first item",
                needsTransformation: 1,
                nestedList: [
                    {
                        nestedString: "sample nested string value",
                    },
                ],
            },
            {
                string: "second item",
                needsTransformation: 2,
                nestedList: [
                    {
                        nestedString: "sample nested string value",
                    },
                ],
            },
        ],
        inlineList: [
            {
                name: "http_proxy",
                value: "http://myProxy.net",
                casing: "upper-and-lower-case",
                disable: false,
            },
            {
                name: "trace_logging_enabled",
                value: "true",
                casing: "upper-case",
                disable: true,
            },
        ],
        boolean: false,
        region: "us-east1",
        select: "select-1",
        number: 1,
        nonDiscriminatorSelect: { selectedOption: Options["First Option"] },
    }),
    exportConfiguration: [
        {
            name: "Export to Sample",
            description: "Sample Export Description",
            export: (inputs: StepConfigurationExportInputs<SampleStepInputs>) => {
                return `String: ${inputs.string}\nNumber: ${inputs.number}\nBoolean: ${inputs.boolean}\nFeed Url: ${inputs.containerImage.feed.url}`;
            },
        },
    ],
    editInputsForm: (inputs) => {
        return [
            text({
                input: inputs.string,
                label: "Some string input",
                helpText: "Enter a string!",
                note: note`Here is a note with ${bold("bold")} text and ${italic("italic")}.
                 This not also contains ${code("some code")} and an external ${link({ title: "link", href: "hello" })}`,
            }),
            checkbox({
                input: inputs.boolean,
                label: "A checkbox input",
                helpText: "Select whether the value is true or false",
            }),
            radioButtons({
                input: inputs.region,
                label: "Region",
                helpText: "Pick a region",
                options: [
                    { label: "US East 1", newValue: "us-east1" },
                    { label: "US East 2", newValue: "us-east2" },
                    { label: "AU East 1", newValue: "au-east1" },
                ],
            }),
            number({
                input: inputs.number,
                label: "A number input",
                helpText: "Enter a number!",
            }),
            select({
                input: inputs.select,
                label: "A select input",
                helpText: "Select an option",
                options: [
                    {
                        label: "Option 1",
                        newValue: "select-1",
                    },
                    {
                        label: "Option 2",
                        newValue: "select-2",
                    },
                    {
                        label: "Option 3",
                        newValue: "select-3",
                    },
                ],
            }),
            select({
                input: inputs.nonDiscriminatorSelect,
                label: "A non-discriminator select input",
                helpText: "Select an option",
                options: [
                    {
                        label: "First Option",
                        newValue: { selectedOption: Options["First Option"] },
                    },
                    {
                        label: "Second Option",
                        newValue: { selectedOption: Options["Second Option"] },
                    },
                    {
                        label: "Third Option",
                        newValue: { selectedOption: Options["Third Option"] },
                    },
                ],
            }),
            sectionGroup({
                title: "A section group",
                content: [
                    packageSelector({
                        input: inputs.package,
                        label: "Package",
                        helpText: "Pick a package",
                    }),
                    containerImageSelector({
                        input: inputs.containerImage,
                        label: "Container Image",
                        helpText: "Pick a container image",
                    }),
                    section({
                        title: "A section",
                        content: [
                            sensitiveText({
                                input: inputs.sensitiveValue,
                                label: "Sensitive value",
                                helpText: "Enter a sensitive value",
                            }),
                        ],
                    }),
                ],
            }),
            list({
                input: inputs.list,
                label: "List",
                helpText: "A list of things",
                newItem: {
                    string: "",
                    needsTransformation: 0,
                    nestedList: [],
                },
                itemLabel: "Thing",
                itemSummary: (arrayItemInputs) => ({ title: arrayItemInputs.string }),
                editItemForm: (arrayItemInputs) => [
                    text({
                        input: arrayItemInputs.string,
                        label: "Some string",
                        helpText: "Enter a string",
                    }),
                    text({
                        input: arrayItemInputs.needsTransformation.convertTo({
                            toNewType: (number) => number.toString(),
                            toOriginalType: (string) => parseInt(string),
                        }),
                        label: "Enter a number",
                        helpText: "You must enter a number here",
                    }),
                    list({
                        input: arrayItemInputs.nestedList,
                        label: "List",
                        helpText: "A list of things",
                        newItem: {
                            nestedString: "",
                        },
                        itemLabel: "Thing",
                        itemSummary: (nestedArrayItemInputs) => ({ title: nestedArrayItemInputs.nestedString }),
                        editItemForm: (nestedArrayItemInputs) => [
                            text({
                                input: nestedArrayItemInputs.nestedString,
                                label: "Some string",
                                helpText: "Enter a string",
                            }),
                        ],
                    }),
                ],
            }),
            inlineList({
                input: inputs.inlineList,
                label: "Environment Variables",
                newItem: {
                    name: "",
                    value: "",
                    disable: false,
                    casing: "upper-case",
                },
                helpText: "Enter your environment variables",
                editItemForm: (envVarInputs) => {
                    const name = inlineText({
                        input: envVarInputs.name,
                        label: "Name",
                    });
                    const value = inlineText({ input: envVarInputs.value, label: "Value" });
                    const casing = inlineSelect({
                        input: envVarInputs.casing,
                        label: "Casing",
                        options: [
                            { newValue: "upper-case", label: "Upper Case" },
                            { newValue: "lower-case", label: "Lower Case" },
                            { newValue: "upper-and-lower-case", label: "Both Upper and Lower Case" },
                        ],
                    });
                    const isDisabled = inlineCheckbox({ input: envVarInputs.disable, label: "Disabled" });
                    return [
                        [name, value],
                        [isDisabled, casing],
                    ];
                },
            }),
            section({
                title: "Union section",
                content: [
                    radioButtons({
                        input: inputs.union,
                        label: "First or Second?",
                        helpText: "Pick between first and second",
                        options: [
                            {
                                label: "First option",
                                newValue: {
                                    type: "first",
                                    valueFromFirst: "some value",
                                },
                            },
                            {
                                label: "Second option",
                                newValue: {
                                    type: "second",
                                    valueFromSecond: { type: "empty" },
                                },
                            },
                        ],
                    }),
                    ...unionUI(inputs.union),
                ],
            }),
            section({
                title: "A second union section",
                content: [
                    select({
                        input: inputs.secondUnion,
                        label: "First or Second (from select)?",
                        helpText: "Pick between first and second",
                        options: [
                            {
                                label: "First option",
                                newValue: {
                                    type: "first",
                                    valueFromFirst: "some value",
                                },
                            },
                            {
                                label: "Second option",
                                newValue: {
                                    type: "second",
                                    valueFromSecond: { type: "empty" },
                                },
                            },
                        ],
                    }),
                    ...unionUI(inputs.secondUnion),
                ],
            }),
        ];
    },
};

function unionUI(inputs: ObjectInputPaths<Union>): StepInputComponent[] {
    if (inputs.type === "first") {
        return [
            text({
                input: inputs.valueFromFirst,
                label: "String for first",
                helpText: "Enter a string for the first option",
            }),
        ];
    }
    return [
        sensitiveText({
            input: inputs.valueFromSecond,
            label: "Sensitive value for second",
            helpText: "Enter a sensitive value for the second option",
        }),
    ];
}
