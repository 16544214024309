import type { CategorizedPageHelpRegistration } from "app/components/ContextualHelpLayout/PageHelpRegistry/Registry/pageHelpRegistry";
import * as React from "react";
import { CommonProjectInsightsHelp } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightViews";
import { CommonInsightsDeploymentFrequencyOverview, CommonInsightsDeploymentFrequencyTopics } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightsDeploymentFrequencyViews";
import pageIds from "~/pageIds";

export const ProjectInsightsDeploymentFrequencyRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().insights.frequency,
    renderOverview: (props) => (
        <>
            <CommonInsightsDeploymentFrequencyOverview /> <CommonProjectInsightsHelp />
        </>
    ),
    renderTopics: (props) => <CommonInsightsDeploymentFrequencyTopics />,
};
