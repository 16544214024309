import classnames from "classnames";
import React from "react";
import IconButtonWithToolTip from "./IconButtonWithToolTip";
import styles from "./styles.module.less";
import type { SharedButtonProps } from "./types";

interface FontAwesomeIconButtonWithToolTipProps extends SharedButtonProps {
    iconClassName: string;
}

export const FontAwesomeIconButtonWithToolTip = ({ iconClassName, disabled, tabIndex, buttonRef, style, toolTipContent, onClick, accessibleName }: FontAwesomeIconButtonWithToolTipProps) => {
    return (
        <IconButtonWithToolTip
            icon={<em className={classnames("fa", iconClassName, styles.fontAwesomeIcon)} aria-hidden="true" />}
            onClick={onClick}
            toolTipContent={toolTipContent}
            disabled={disabled}
            tabIndex={tabIndex}
            buttonRef={buttonRef}
            style={style}
            accessibleName={accessibleName}
        />
    );
};

export default FontAwesomeIconButtonWithToolTip;
