import React from "react";
import { BooleanRadioButtonGroup, ExpandableFormSection, Note, RadioButton, Summary } from "~/components/form";

type CompositeDockerHubClientFeature = {
    isEnabled: boolean;
    onChange: (isEnabled: boolean) => void;
};

export function CompositeDockerHubClientFeature({ isEnabled, onChange }: CompositeDockerHubClientFeature) {
    return (
        <ExpandableFormSection
            key="IsCompositeDockerHubRegistryFeedEnabled"
            errorKey="IsCompositeDockerHubRegistryFeedEnabled"
            title="Composite DockerHub Client"
            summary={isEnabled ? Summary.default("Enabled") : Summary.summary("Disabled")}
            help="Enable/disable Composite v1/v2 DockerHub Registry Feed support."
        >
            <BooleanRadioButtonGroup value={isEnabled} onChange={onChange}>
                <RadioButton value={true} label="Enabled" />
                <RadioButton value={false} label="Disabled" />
            </BooleanRadioButtonGroup>
            <Note style={{ marginTop: "1rem" }}>
                This feature enables a new DockerHub integration mechanism which utilises both the v1 and v2 DockerHub Registry APIs to provide a fully functional feed experience.
                <em>You should only disable this feature if you have been asked to by Octopus Support.</em>
            </Note>
        </ExpandableFormSection>
    );
}
