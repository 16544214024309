import cn from "classnames";
import type { PropsWithChildren } from "react";
import * as React from "react";
import defaultBlankPageImgDark from "~/components/ProjectBasedActivation/assets/dark/darkimg-defaultblankspace.svg";
import defaultBlankPageImg from "~/components/ProjectBasedActivation/assets/img-defaultblankspace.svg";
import styles from "~/components/ProjectBasedActivation/styles.module.less";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";

interface OnboardingBlankPageProps {
    title: string;
    description?: React.ReactNode | string;
    image?: string;
    imageDark?: string;
    imageAlt?: string;
    learnMore?: React.ReactNode;
    actionButtons?: React.ReactNode;
}

export default (props: PropsWithChildren<OnboardingBlankPageProps>) => {
    const isDarkMode = useThemePaletteType() === "dark";
    const image = isDarkMode ? props.imageDark ?? defaultBlankPageImgDark : props.image ?? defaultBlankPageImg;
    return (
        <div className={styles.blankPageOuterContainer}>
            <div className={cn(styles.blankPageContentContainer)}>
                {props.title && (
                    <div>
                        <h2 className={styles.header}>{props.title}</h2>
                        {props.description && <div className={styles.description}>{props.description}</div>}
                        <strong>{props.learnMore && <div className={styles.externalLinks}>{props.learnMore}</div>}</strong>
                        {props.actionButtons}
                    </div>
                )}
            </div>
            <div className={styles.blankPageImage}>
                <img src={image} width={"100%"} alt={props.imageAlt} />
            </div>
        </div>
    );
};
