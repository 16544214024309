import { throttle } from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { LinksMenu } from "~/components/LinksMenu/LinksMenu";
import { LinksMenuButton } from "~/components/LinksMenu/LinksMenuButton";
import { hasActiveDescendants, isMenuGroup } from "~/components/LinksMenu/MenuNode";
import type { MenuNode } from "~/components/LinksMenu/MenuNode";
import InternalNavLink from "../Navigation/InternalNavLink/InternalNavLink";
import styles from "./style.module.less";

interface PriorityNavigationProps {
    className: string;
    activeItemClassName: string;
    navigationItems: MenuNode[];
    maxNavigationItems: number;
    showHamburgerIcon?: boolean;
}

interface PriorityNavigationState {
    itemWidths: number[] | undefined;
    priorityItems: MenuNode[];
    moreItems: MenuNode[];
    isMoreMenuOpen: boolean;
}

class PriorityNavigation extends BaseComponent<PriorityNavigationProps, PriorityNavigationState> {
    lastKnownContainerWidth: number | undefined = undefined;
    resizeObserver: ResizeObserver;

    constructor(props: PriorityNavigationProps) {
        super(props);

        this.resizeObserver = new ResizeObserver(throttle(this.onResize, 100));

        this.state = {
            itemWidths: undefined,
            isMoreMenuOpen: false,
            priorityItems: this.props.navigationItems,
            moreItems: [],
        };
    }

    UNSAFE_componentWillReceiveProps() {
        if (this.lastKnownContainerWidth !== undefined && this.state.itemWidths !== undefined) {
            this.updateNavigation(this.lastKnownContainerWidth, this.state.itemWidths);
        }
    }

    componentWillUnmount() {
        this.resizeObserver.disconnect();
    }

    render() {
        const hasMeasuredItemWidths = this.state.itemWidths !== undefined;

        const priorityItems = hasMeasuredItemWidths ? this.state.priorityItems : this.props.navigationItems;
        const moreItems = hasMeasuredItemWidths ? this.state.moreItems : [];
        const showMoreItems = !hasMeasuredItemWidths || moreItems.length > 0;

        return (
            <nav className={`${this.props.className} ${styles.container}`}>
                <ul ref={this.setNavigation}>
                    {priorityItems.map((item: MenuNode, index: number) => (
                        <RoutedNavItem key={`navItem-${index}`} item={item} activeItemClassName={this.props.activeItemClassName} />
                    ))}
                    {showMoreItems && <LinksMenuButton activeItemClassName={this.props.activeItemClassName} icon={this.props.showHamburgerIcon && priorityItems.length === 0 ? "fa-solid fa-bars" : undefined} label="More" moreItems={moreItems} />}
                </ul>
            </nav>
        );
    }

    private howManyItemsInMenuArray = (array: number[], outerWidth: number, initialWidth: number, maximumNumberInNav: number) => {
        let total = initialWidth;
        for (let i = 0; i < array.length; i++) {
            if (i > maximumNumberInNav) {
                return maximumNumberInNav;
            }

            total += array[i];

            if (total > (outerWidth || 0)) {
                return i;
            }
        }

        return array.length;
    };

    private updateNavigation = (containerWidth: number, itemWidths: number[]) => {
        const arrayAmount = this.howManyItemsInMenuArray(itemWidths, containerWidth, itemWidths[itemWidths.length - 1], this.props.maxNavigationItems);
        const navItemsCopy = this.props.navigationItems;
        const priorityItems = navItemsCopy.slice(0, arrayAmount);

        const moreItems = priorityItems.length !== navItemsCopy.length ? navItemsCopy.slice(arrayAmount, navItemsCopy.length) : [];

        this.setState({
            priorityItems,
            moreItems,
        });
    };

    private onResize = (entries: ResizeObserverEntry[]) => {
        const navigation = entries[0].target;

        if (this.state.itemWidths === undefined) {
            const itemWidths = Array.prototype.slice.call(navigation.children).map((item: HTMLElement) => item.getBoundingClientRect().width);

            this.setState({ itemWidths }, () => {
                this.updateNavigation(navigation.clientWidth, itemWidths);
            });
        } else if (navigation.clientWidth !== this.lastKnownContainerWidth) {
            this.updateNavigation(navigation.clientWidth, this.state.itemWidths);
        }

        this.lastKnownContainerWidth = navigation.clientWidth;
    };

    private setNavigation = (el: HTMLUListElement) => {
        this.resizeObserver.disconnect();

        if (el !== null) {
            this.resizeObserver.observe(el);
        }
    };
}

interface NavItemComponentProps {
    item: MenuNode;
    activeItemClassName: string;
}

type NavItemProps = NavItemComponentProps & RouteComponentProps<{ spaceId: string }>;

const NavItem: React.SFC<NavItemProps> = (props: NavItemProps) => {
    const item = props.item;

    if (isMenuGroup(item)) {
        const isActive = hasActiveDescendants(props.location.pathname, props.match.params.spaceId, item);
        return (
            <li>
                <LinksMenu activeItemClassName={isActive ? props.activeItemClassName : undefined} items={item.children} label={item.label} />
            </li>
        );
    }

    return (
        <li>
            <InternalNavLink activeClassName={props.activeItemClassName} to={item.url} exact={item.exact} title={item.title} className={item.linkClassName}>
                {item.icon}
                <span>{item.text}</span>
                {item.accessoryView}
            </InternalNavLink>
        </li>
    );
};

const RoutedNavItem = withRouter(NavItem);

export default PriorityNavigation;
