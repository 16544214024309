import React, { useEffect } from "react";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import { InsightsTooltip } from "~/areas/insights/components/ChartTooltips/InsightsTooltip/InsightsTooltip";
import LineChart from "~/areas/insights/components/Charts/LineChart";
import { InsightsChartCard } from "~/areas/insights/components/InsightsChartCard";
import { SparklineTrendPair } from "~/areas/insights/components/Reports/TrendTables/SparklineTrendPair/SparklineTrendPair";
import { getChartAxisDateFormatter } from "~/areas/insights/dataTransformation/dateHelpers";
import { getChartReadyInsightsData } from "~/areas/insights/dataTransformation/getChartReadyInsightsData";
import { getEvenlySpacedTickArray } from "~/areas/insights/dataTransformation/getYAxisDataMax";
import { insightsCadenceLookup, insightsCadenceXAxisTickInterval } from "~/areas/insights/insightsCadence";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { DownloadProjectInsightsCsvButton } from "../DownloadProjectInsightsCsvButton";
import { ProjectInsightsDataLoader } from "../ProjectInsightsDataLoader/ProjectInsightsDataLoader";
import type { ProjectInsightsPageProps } from "../ProjectInsightsDataLoader/ProjectInsightsDataLoader";
import { ProjectFailureRateTrendTable } from "./ProjectFailureRateTrendTable";
import styles from "./ProjectInsightsFailureRate.module.less";

type ProjectInsightsFailureRateInnerProps = ProjectInsightsPageProps;

function ProjectInsightsFailureRateInner({ project, bffResponse, busy, filter }: ProjectInsightsFailureRateInnerProps) {
    const { cadence } = filter;

    const chartData = getChartReadyInsightsData(bffResponse.Series);

    const dispatchAction = useAnalyticSimpleActionDispatch();

    useEffect(() => {
        dispatchAction("View Project Insights Failure Rate");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const currentGranularity = insightsCadenceLookup[cadence].granularity;

    const dateFormatter = getChartAxisDateFormatter(currentGranularity);

    return (
        <main>
            <section className={styles.content}>
                <InsightsChartCard height={500}>
                    <LineChart
                        loading={Boolean(busy)}
                        data={chartData}
                        dataKey="DeploymentFailureRate.ComputedFailureRate"
                        yAxisLabel="Failure rate (%)"
                        xAxisDataKey="StartOfInterval"
                        xAxisType="category"
                        yAxisType="number"
                        yAxisDomain={[0, 100]}
                        yAxisTickArray={getEvenlySpacedTickArray(100)}
                        xTickFormatter={dateFormatter}
                        tooltip={<InsightsTooltip dataKey="DeploymentFailureRate.ComputedFailureRate" valueFormatter={(v) => `${v}%`} cadence={cadence} />}
                        xAxisTickInterval={insightsCadenceXAxisTickInterval[cadence]}
                    />
                </InsightsChartCard>
                <div className={styles.csvDownloadWrap}>
                    <DownloadProjectInsightsCsvButton project={project} filter={filter} />
                </div>
            </section>
            <section>
                {bffResponse.ChangeFailureRate.TenantTrends !== null && (
                    <SparklineTrendPair
                        sectionTitle="Tenants"
                        leftData={bffResponse.ChangeFailureRate.TenantTrends.Up}
                        rightData={bffResponse.ChangeFailureRate.TenantTrends.Down}
                        leftTitle="Trending up"
                        rightTitle="Trending down"
                        note={
                            <>
                                The tenants that have trended up or down in the last 28 days compared with the previous 28 days. The first 10 in descending order by the percentage difference between the current and previous value are shown. The value
                                shown is the average <ExternalLink href="InsightsDeploymentFailureRate">deployment failure rate</ExternalLink> for the last 28 days.
                            </>
                        }
                    />
                )}
                <ProjectFailureRateTrendTable title="Recent failures" data={bffResponse.ChangeFailureRate.RecentFailures} project={project} />
            </section>
        </main>
    );
}

export function ProjectInsightsFailureRate() {
    return (
        <ProjectInsightsDataLoader title="Deployment failure rate" trendKey="ChangeFailureRate">
            {(props) => <ProjectInsightsFailureRateInner {...props} />}
        </ProjectInsightsDataLoader>
    );
}
