/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type * as React from "react";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { expanderActions } from "~/components/form/Sections/reducers/expanders";

export interface GlobalDispatchControlExpandersProps {
    setExpanderState(key: string, open: boolean): void;
}

// HOC that adds an openExpander prop for direct control over expanders
export function ControlExpanders<Props>(Comp: React.ComponentType<Props & GlobalDispatchControlExpandersProps>) {
    type ExternalProps = Omit<Props, keyof GlobalDispatchControlExpandersProps>;
    const mapGlobalActionDispatchersToProps = (dispatch: Dispatch) => {
        return {
            setExpanderState: (key: string, open: boolean) => dispatch(expanderActions.onExpanderStateChanged({ containerKey: null, key, expanded: open })),
        };
    };

    const BaseComponent = Comp as React.ComponentType<GlobalDispatchControlExpandersProps>;

    return connect(null, mapGlobalActionDispatchersToProps)(BaseComponent) as any as React.ComponentClass<ExternalProps>;
}
