import cn from "classnames";
import type { PropsWithChildren } from "react";
import React from "react";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import styles from "./InsightsChartCard.module.less";

interface InsightsChardCardProps {
    height?: number;
}

export function InsightsChartCard({ children, height = 350 }: PropsWithChildren<InsightsChardCardProps>) {
    const palette = useThemePaletteType();

    const isLight = palette === "light";

    const className = cn(styles.chartCard, {
        [styles.chartCardLight]: isLight,
        [styles.chartCardDark]: !isLight,
    });

    return (
        <div className={className} style={{ height }}>
            {children}
        </div>
    );
}
