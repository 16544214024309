/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type { History } from "history";
import * as React from "react";
type LocationDescriptor = History.LocationDescriptor;
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import type { Dispatch } from "redux";
import { AnalyticLinkProvider } from "~/analytics/AnalyticLink";
import configurationSelectors from "~/areas/configuration/reducers/selectors";
import { drawerBreakpointWidth } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import { toggleDrawer, isDrawerOpen } from "~/components/ContextualHelpLayout/reducers";
import IconButton, { Icon } from "~/components/IconButton/IconButton";
import Breadcrumb from "~/primitiveComponents/navigation/Breadcrumb";
import PageTitleHelper from "~/utils/PageTitleHelper";
import type { ComponentProps } from "~/utils/types";
import InternalNavLink from "../Navigation/InternalNavLink/InternalNavLink";
import styles from "./style.module.less";

interface GlobalConnectedProps {
    isDrawerOpen?: boolean;
    isHelpSidebarEnabled?: boolean;
}

interface GlobalDispatchProps {
    toggleDrawer?(): void;
}

interface AreaTitlePropsInternal {
    title?: string;
    link?: string;
    breadcrumbTitle?: string; // If specified, this text will display above the title.
    breadcrumbPath?: LocationDescriptor; // If specified, this will link your breadcrumb title.
    busyIndicator?: JSX.Element;
    hideHelpButton?: boolean;
}

type AreaTitleProps = AreaTitlePropsInternal & GlobalConnectedProps & GlobalDispatchProps;

class AreaTitleInternal extends React.Component<AreaTitleProps, never> {
    constructor(props: AreaTitleProps) {
        super(props);
        PageTitleHelper.setPageTitle(props.title, props.breadcrumbTitle);
    }

    renderActions() {
        if (!this.props.isHelpSidebarEnabled || this.props.hideHelpButton) {
            return null;
        }

        return (
            <React.Fragment>
                <MediaQuery minWidth={drawerBreakpointWidth}>
                    <div className={styles.helpAction}>
                        <IconButton toolTipContent={this.props.isDrawerOpen ? "Hide help" : "Show help"} onClick={this.props.toggleDrawer} icon={this.props.isDrawerOpen ? Icon.CloseHelp : Icon.OpenHelp} />
                    </div>
                </MediaQuery>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div className={styles.titleBar}>
                <div className={styles.rowSpaced}>
                    <AnalyticLinkProvider location="Area Title">
                        <div className={styles.title}>
                            <Breadcrumb title={this.props.breadcrumbTitle} path={this.props.breadcrumbPath} isAreaLevelBreadcrumb={true} />
                            <h1>
                                <InternalNavLink className={styles.pageTitleLink} to={this.props.link!}>
                                    {this.props.title}
                                </InternalNavLink>
                            </h1>
                        </div>
                        <div className={styles.actions}>
                            {this.props.children}
                            {this.renderActions()}
                        </div>
                    </AnalyticLinkProvider>
                </div>
                {this.props.busyIndicator}
            </div>
        );
    }
}

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isDrawerOpen: isDrawerOpen(state),
        isHelpSidebarEnabled: configurationSelectors.createFeatureEnabledSelector((t) => t.isHelpSidebarEnabled)(state),
    };
};

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch): GlobalDispatchProps => {
    return {
        toggleDrawer: () => dispatch(toggleDrawer()),
    };
};

const AreaTitle = connect(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(AreaTitleInternal);

export default AreaTitle as React.ComponentType<ComponentProps<typeof AreaTitle>>;
