import * as React from "react";
import ActionButton, { ActionButtonType } from "~/components/Button";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import { useProjectContext } from "../../context";

export const GitRepositoryErrorCallout: React.FC<{ additionalIntroText?: string; calloutType?: CalloutType }> = ({ additionalIntroText, calloutType }) => {
    const [showDetails, setShowDetails] = React.useState(false);
    const projectContext = useProjectContext();

    if (!projectContext.state.gitVariablesHasError) {
        // There is no exception (yet), so don't render anything.
        return null;
    }

    const errorMessageElements = projectContext.state.gitVariablesErrorMessages.map((errorMessage) => <div>{errorMessage}</div>);

    return (
        <Callout type={calloutType || CalloutType.Warning} title="Git repository error">
            <div>There was an error retrieving the variables from the Git repository. {additionalIntroText}</div>
            {<ActionButton label={showDetails ? "Hide Details" : "Show Details"} type={ActionButtonType.Ternary} onClick={() => setShowDetails(!showDetails)} />}
            {showDetails && errorMessageElements}
        </Callout>
    );
};
