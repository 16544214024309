import * as React from "react";
import { GitCallouts } from "~/areas/projects/components/VersionControl/GitCallouts";
import type { FormPaperLayoutProps } from "~/components/FormPaperLayout/FormPaperLayout";
import FormPaperLayout from "~/components/FormPaperLayout/FormPaperLayout";

export const ProjectContextFormPaperLayout: React.FC<FormPaperLayoutProps> = ({ children, ...rest }) => (
    <FormPaperLayout {...rest} callout={<GitCallouts />}>
        {children}
    </FormPaperLayout>
);
