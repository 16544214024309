import React from "react";
import { DefaultOverview, DefaultTopics } from "./DefaultViews";

export const CommonConfigurationLetsEncryptOverview: React.StatelessComponent<{}> = (props) => {
    return <DefaultOverview />;
};

export const CommonConfigurationLetsEncryptTopics: React.StatelessComponent<{}> = (props) => {
    return <DefaultTopics />;
};
