import React from "react";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";

export const DeleteStepsCallout: React.FC = () => {
    return (
        <Callout title="This is a destructive action" type={CalloutType.Danger}>
            This action <strong>cannot</strong> be undone. This will permanently delete all steps from this process.
        </Callout>
    );
};
