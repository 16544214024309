import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import type { FieldErrors } from "~/components/DataBaseComponent/Errors";
import { useExpandAllExpanders } from "~/components/Expandable/useExpandAllExpanders";
import { useExpandExpanders } from "~/components/Expandable/useExpandExpanders";
import type { ExpanderContainer } from "~/components/form/Sections/reducers/expanders";
import { defaultContainerKey, defaultExpanderContainer, expanderActions, UseInitialValueOfContainer } from "~/components/form/Sections/reducers/expanders";
import store from "~/store";
import styles from "./style.module.less";

export const toggleExpandos = (expanded: boolean, containerKey?: string) => {
    store.dispatch(expanderActions.onToggleAll({ containerKey: containerKey ?? null, expanded }));
};

export type ExpansionButtonsProps = {
    expandAllOnMount?: boolean;
    errors?: FieldErrors;
    containerKey?: string;
};

function ExpansionButtons({ containerKey, expandAllOnMount, errors }: ExpansionButtonsProps) {
    const dispatch = useDispatch();
    const expandExpanders = useExpandExpanders(containerKey);
    const expandAll = useExpandAllExpanders(containerKey);

    React.useEffect(() => {
        dispatch(expanderActions.onExpanderSetInitialState({ containerKey: containerKey ?? null, initialState: expandAllOnMount ?? false }));

        return () => {
            dispatch(expanderActions.onExpanderContainerDestroyed({ containerKey: containerKey ?? null }));
        };
    }, [containerKey, dispatch, expandAllOnMount]);

    React.useEffect(() => {
        const errorKeys = Object.keys(errors ?? {});
        if (errorKeys.length > 0) {
            expandExpanders(errorKeys);
        }
    }, [errors, expandExpanders]);

    React.useEffect(() => {
        if (expandAllOnMount) {
            expandAll(true);
        }
    }, [expandAll, expandAllOnMount]);

    const onExpandAll = React.useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            expandAll(true);
        },
        [expandAll]
    );

    const onCollapseAll = React.useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            expandAll(false);
        },
        [expandAll]
    );

    const { hasCollapsedExpanders, hasExpandedExpanders, hasExpanders } = useSelector((state: GlobalState) => {
        const container = state.expanders?.[containerKey || defaultContainerKey] ?? defaultExpanderContainer();
        const expanded = Object.keys(container.expanderValues).map((errorKey) => getValueForKey(container, errorKey));
        return { hasExpandedExpanders: expanded.some((e) => e), hasCollapsedExpanders: expanded.some((e) => !e), hasExpanders: expanded.length > 0 };
    });

    return (
        <>
            {hasExpanders && (
                <div className={styles.linkContainer}>
                    {hasCollapsedExpanders && (
                        <a role="button" href="#" className={styles.expandLink} onClick={onExpandAll}>
                            EXPAND ALL
                        </a>
                    )}
                    {hasExpandedExpanders && (
                        <a role="button" href="#" className={styles.expandLink} onClick={onCollapseAll}>
                            COLLAPSE ALL
                        </a>
                    )}
                </div>
            )}
        </>
    );
}

function getValueForKey(container: ExpanderContainer, errorKey: string) {
    const value = container.expanderValues[errorKey];
    return value === UseInitialValueOfContainer ? container.initialState : value;
}

export default ExpansionButtons;
