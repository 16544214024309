/* eslint-disable @typescript-eslint/no-explicit-any */

import cn from "classnames";
import * as React from "react";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenu } from "~/components/OverflowMenu/OverflowMenu";
import styles from "./style.module.less";

interface ActionListWithSubItemProps {
    actions: any[];
    overflowMenuItems?: Array<MenuItem | MenuItem[]>;
    alignStart?: boolean;
    subItem?: any;
}

export function ActionListWithSubItem(props: ActionListWithSubItemProps) {
    const actions: any[] = props.actions.map((action, index) => (action ? React.cloneElement(action, { ...action.props, key: index }) : null));
    const overflowMenu = props.overflowMenuItems && props.overflowMenuItems.length > 0 ? <OverflowMenu menuItems={props.overflowMenuItems} /> : undefined;

    return (
        <div className={cn(styles.gridContainer, overflowMenu ? styles.overflow : styles.noOverflow)}>
            <div className={cn(styles.actions, styles.actionList, props.alignStart ? styles.alignStart : styles.alignEnd)}>{actions}</div>
            {overflowMenu && <div className={cn(styles.overflow)}>{overflowMenu}</div>}
            {props.subItem && <div className={cn(styles.subItem, props.alignStart ? styles.alignStart : styles.alignEnd)}>{props.subItem}</div>}
        </div>
    );
}
