import React from "react";
import SlugSummary from "~/primitiveComponents/form/Slugs/SlugSummary";
import styles from "./style.module.less";

type Props = {
    name: string;
    slug?: string | null;
};

export default function NameSummaryWithSlug({ name, slug }: Props) {
    return (
        <>
            <span className={styles.nameSummary}>{name}</span>
            <SlugSummary slug={slug} />
        </>
    );
}
