import React from "react";
import { BooleanRadioButtonGroup, ExpandableFormSection, RadioButton, Summary } from "~/components/form";

interface Props {
    isEnabled: boolean;
    onChange: (isEnabled: boolean) => void;
}

export function InsightsExperimentalFeature({ isEnabled, onChange }: Props) {
    return (
        <ExpandableFormSection key="IsInsightsEnabled" errorKey="IsInsightsEnabled" title="Insights" summary={isEnabled ? Summary.default("Enabled") : Summary.summary("Disabled")} help="Enable/Disable the Insights area">
            <BooleanRadioButtonGroup value={isEnabled} onChange={onChange}>
                <RadioButton value={true} label="Enabled" />
                <RadioButton value={false} label="Disabled" isDefault={true} />
            </BooleanRadioButtonGroup>
        </ExpandableFormSection>
    );
}
