import React from "react";
import { useNotifyThemeChanged } from "~/components/Theme/analytics/useNotifyThemeChanged";
import useLocalStorage from "~/hooks/useLocalStorage";
import type { ThemePaletteType } from "~/theme/index";

export function useThemePaletteType(): ThemePaletteType {
    const { coercedPaletteType } = useThemeFromLocalStorage();
    return coercedPaletteType;
}

export function useSetThemePaletteType(): SetThemeValue {
    const { coercedPaletteType, setPaletteType } = useThemeFromLocalStorage();
    const notifyThemeChanged = useNotifyThemeChanged();

    return React.useCallback(
        (value) => {
            setPaletteType(value);
            notifyThemeChanged({ previousTheme: coercedPaletteType, selectedTheme: value });
        },
        [coercedPaletteType, notifyThemeChanged, setPaletteType]
    );
}

export function useInitializeThemeInStorageEffect() {
    const { paletteType, setPaletteType, coercedPaletteType } = useThemeFromLocalStorage();

    React.useEffect(() => {
        if (paletteType === "unassigned") {
            setPaletteType(coercedPaletteType);
        }
    }, [coercedPaletteType, paletteType, setPaletteType]);
}

type SetThemeValue = (value: ThemePaletteType) => void;

interface ThemeFromLocalStorageResult {
    paletteType: ThemePaletteType | "unassigned";
    coercedPaletteType: ThemePaletteType;
    setPaletteType: SetThemeValue;
}

function useThemeFromLocalStorage(): ThemeFromLocalStorageResult {
    const [paletteFromStorage, setPaletteType] = useLocalStorage<ThemePaletteType | "unassigned">("theme", "unassigned");
    const browserPreferredPalette = getBrowserPreferredPalette("light");

    return {
        paletteType: paletteFromStorage,
        coercedPaletteType: paletteFromStorage === "unassigned" ? browserPreferredPalette : paletteFromStorage,
        setPaletteType,
    };
}

function getBrowserPreferredPalette(fallback: ThemePaletteType): ThemePaletteType {
    if (!window.matchMedia) {
        return fallback;
    } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        return "dark";
    } else if (window.matchMedia("(prefers-color-scheme: light)").matches) {
        return "light";
    } else {
        return fallback;
    }
}
