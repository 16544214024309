import React from "react";
import CustomDialog from "~/components/Dialog/CustomDialog";
import { FundamentalsGuideDialogLayout } from "~/components/GettingStarted/FundamentalsGuide/FundamentalsGuideDialogLayout";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { LearnMoreResources, NeedHelpResources, NewToOctopusResources, OverviewContainer, SupportResources, TopicsContainer } from "./CommonViews";
import styles from "./Registrations.module.less";

export const DefaultOverview: React.StatelessComponent<{}> = (props) => {
    const [showFundamentals, setShowFundamentals] = React.useState(false);

    return (
        <OverviewContainer>
            <TopicsContainer>
                <CustomDialog open={showFundamentals} close={() => setShowFundamentals(false)} render={(renderProps) => <FundamentalsGuideDialogLayout {...renderProps} />} />
                <NeedHelpResources>
                    <p>
                        Search our{" "}
                        <ExternalLink href="DocumentationHome" trackAnalytics={true}>
                            documentation
                        </ExternalLink>{" "}
                        or see the resources tab for helpful topics.
                    </p>
                </NeedHelpResources>
                <NewToOctopusResources>
                    <div>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setShowFundamentals(true);
                            }}
                        >
                            Quick guide
                        </a>
                    </div>
                    <div>
                        <ExternalLink href="GettingStarted" trackAnalytics={true}>
                            Overview
                        </ExternalLink>
                    </div>
                    <div>
                        <ExternalLink href="GettingStartedVideos" trackAnalytics={true}>
                            Video tutorials
                        </ExternalLink>
                    </div>
                </NewToOctopusResources>
            </TopicsContainer>
        </OverviewContainer>
    );
};

export const DefaultTopics: React.StatelessComponent<{}> = (props) => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="DocumentationHome" trackAnalytics={true}>
                        Documentation
                    </ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};

interface DefaultGettingStartedState {
    showFundamentals: boolean;
}

export class DefaultGettingStarted extends React.Component<{}, DefaultGettingStartedState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            showFundamentals: false,
        };
    }

    render() {
        return (
            <div className={styles.gettingStarted}>
                <SupportResources>
                    <div>
                        <ExternalLink href="Slack" trackAnalytics={true}>
                            Join the Slack Community
                        </ExternalLink>
                    </div>
                    <div>
                        <ExternalLink href="https://www.youtube.com/playlist?list=PLAGskdGvlaw3-cd9rPiwhwfUo7kDGnOBh" trackAnalytics={true}>
                            Ask Octopus Training Videos
                        </ExternalLink>
                    </div>
                    <div>
                        <ExternalLink href="HelpMakeSuggestion" trackAnalytics={true}>
                            Make a Suggestion
                        </ExternalLink>
                    </div>
                    <div>
                        <ExternalLink href="DocumentationHome" trackAnalytics={true}>
                            Search Documentation
                        </ExternalLink>
                    </div>
                    <div>
                        <ExternalLink href={window.location.origin + window.location.pathname + "/../swaggerui/"} trackAnalytics={true}>
                            Search API Documentation
                        </ExternalLink>
                    </div>
                    <div>
                        <ExternalLink href="https://octopus.com/downloads" trackAnalytics={true}>
                            See Additional Downloads
                        </ExternalLink>
                    </div>
                </SupportResources>
            </div>
        );
    }
}
